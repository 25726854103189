import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

type MyProps = {
  t: (arg: string) => string;
  i18n: {
    language: string;
  };
};

type MyState = {};

class LoginPageCommon extends PureComponent<MyProps, MyState> {
  render() {
    const {
      t,
      i18n: { language },
    } = this.props;

    return (
      <>
        <h1 style={{ marginLeft: '97px', fontSize: '46px' }}>
          Your Pages <br /> in One{' '}
          <img src="../static/assets/images/login/login-title-img.png" alt="" />{' '}
          Link{' '}
        </h1>

        {/* 
                <div style={{position:'relative'}}>
                    <img src="../static/assets/images/login/top-left.png" alt="" />
                    <img src="../static/assets/images/login/top-middle.png" alt="" />
                    <img src="../static/assets/images/login/top-right.png" alt="" />
                </div>

                <div>
                    <img src="../static/assets/images/login/bottom-left.png" alt="" />
                    <img src="../static/assets/images/login/bottom-right.png" alt="" />
                </div> */}

        <div
          style={{
            display: 'flex',
            paddingLeft: '142px',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            maxWidth: '100%',
            marginLeft: 'auto',
          }}
        >
          <div style={{ paddingRight: '37px', width: '40%' }}>
            <img
              src="../static/assets/images/login/top-left.png"
              alt=""
              style={{ marginBottom: '37px', width: '100%' }}
            />
            <img
              src="../static/assets/images/login/bottom-left.png"
              alt=""
              style={{ width: '100%' }}
            />
          </div>

          <div style={{ paddingRight: '37px', width: '40%' }}>
            <img
              src="../static/assets/images/login/top-middle.png"
              alt=""
              style={{ marginBottom: '37px', width: '100%' }}
            />
            <img
              src="../static/assets/images/login/bottom-middle.png"
              alt=""
              style={{ width: '100%' }}
            />
          </div>

          <div style={{ paddingRight: 'right', width: '11%' }}>
            <img
              src="../static/assets/images/login/top-right.png"
              alt=""
              style={{ marginBottom: '37px', width: '100%' }}
            />
            <img
              src="../static/assets/images/login/bottom-right.png"
              alt=""
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div></div>
      </>
    );
  }
}
export default withTranslation('common')(LoginPageCommon);
