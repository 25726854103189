import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Col } from '../GridSystem';
import { MdLock } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiFillInfoCircle,
} from 'react-icons/ai';
import {
  Wrapper,
  Left,
  Right,
  StyledImg,
  SignToContinue,
  Form,
  Label,
  Input,
  Submit,
  ErrMsg,
  DontHaveAccount,
  StyledLink,
  StyledBg,
  StyledReach,
  RightContent,
  StyledRow,
  LoginWrapper,
  LeftLoginBox,
} from './LoginPageView.styled';
import Loading from '../Loading';
import router from 'next/router';
import LoginPageCommon from '../common_component/LoginPageCommon';

type Props = {
  language: string;
  t: (arg: string) => string;
  onSubmit: (data: { username: string; password: string }) => void;
  authError: string | null;
  isLoading: boolean;
  currentReachURL: string;
  changeState: () => void;
};

const LoginPageView: React.FC<Props> = ({
  language,
  t,
  onSubmit,
  authError,
  isLoading,
  currentReachURL,
  changeState,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [visible, setVisible] = useState(false);
  const [acitveBtn, setActiveBtn] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length !== 0 || authError?.length !== 0) {
      setActiveBtn(false);
      return;
    }
  }, [errors, authError]);

  return (
    <Wrapper>
      <Left>
        <Grid style={{ height: '100%' }}>
          <StyledRow>
            <LeftLoginBox>
              <Col xs={12} sm={12} md={12} lg={12}>
                <StyledImg src="/static/reachplus-full-logo.svg" alt="" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <SignToContinue>
                  {/* {t('login.sign-in-to-reach-to-continue')} */}
                  {t('login.welcome-back')}
                  <span>{t('login.enter-your-credentials')}</span>
                </SignToContinue>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Label>
                    {/* {t('login.email-address')} */}
                    <div
                      style={{ position: 'relative' }}
                      className={
                        errors.username || authError ? 'validation-error' : ''
                      }
                    >
                      <Input
                        disabled={isLoading}
                        type="text"
                        name="username"
                        placeholder="Username"
                        ref={register({ required: true })}
                        style={
                          errors.username && { border: '1px solid #FB4949' }
                        }
                        onFocus={e => changeState()}
                        onChange={e =>
                          e.target.value === ''
                            ? setActiveBtn(false)
                            : setActiveBtn(true)
                        }
                      />
                      <FaUserAlt className="lefticon" />
                    </div>

                    {errors.username && (
                      <ErrMsg>
                        <AiFillInfoCircle />{' '}
                        {`${t('login.username')} ${t('login.required')}`}
                      </ErrMsg>
                    )}
                  </Label>
                  <Label>
                    {/* {t('login.password')} */}
                    <div
                      style={{ position: 'relative' }}
                      className={
                        errors.password || authError ? 'validation-error' : ''
                      }
                    >
                      <Input
                        disabled={isLoading}
                        type={visible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        ref={register({ required: true })}
                        onFocus={e => changeState()}
                        onChange={e =>
                          e.target.value === ''
                            ? setActiveBtn(false)
                            : setActiveBtn(true)
                        }
                      />
                      <MdLock className="lefticon" />
                      {visible ? (
                        <AiFillEye
                          className="righticon visible"
                          onClick={() => setVisible(!visible)}
                        />
                      ) : (
                        <AiFillEyeInvisible
                          className="righticon"
                          onClick={() => setVisible(!visible)}
                        />
                      )}
                    </div>
                    {errors.password && (
                      <ErrMsg>
                        <AiFillInfoCircle />{' '}
                        {`${t('login.password')} ${t('login.required')}`}
                      </ErrMsg>
                    )}
                  </Label>

                  <LoginWrapper>
                    <Submit
                      disabled={isLoading}
                      type="submit"
                      active={acitveBtn}
                    >
                      {!isLoading ? t('Login') : <Loading options={false} />}
                    </Submit>

                    <StyledLink href="https://reach.link/forgot-password">
                      {t('login.forgot-password')}
                    </StyledLink>
                  </LoginWrapper>
                </Form>
                {authError && Object.keys(errors).length === 0 && (
                  <ErrMsg authError>
                    <AiFillInfoCircle /> {authError}
                  </ErrMsg>
                )}
              </Col>

              <DontHaveAccount>
                {t('login.dont-have-an-account?')}
                <StyledLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${currentReachURL}/register`}
                >
                  {t('login.sign-up')}
                </StyledLink>
              </DontHaveAccount>
            </LeftLoginBox>
          </StyledRow>
        </Grid>
      </Left>
      <Right>
        <RightContent>
          <LoginPageCommon />
        </RightContent>
      </Right>
    </Wrapper>
  );
};

export default LoginPageView;

// w {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
// }
