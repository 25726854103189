// import { PureComponent } from 'react';
// import Router from 'next/router';
// import { login } from '../utils/auth';
// import { withTranslation } from '../i18n';
// import { logEvent, logPageView } from '../utils/analytics';
// import { postUrl } from '../utils/api.js';
// import { loggedIn } from '../utils/auth';
// import LoginPageView from '../components/LoginPageView';

// class Login extends PureComponent<MyProps, MyState> {
//   state = {
//     isLoading: false,
//     error: '',
//   };

//   componentDidMount() {
//     logPageView(window.location.pathname);
//     if (loggedIn()) {
//       Router.push('/'); // redirect if you're already logged in
//     }
//   }

//   handleLoginSubmit = data => {
//     this.setState(
//       {
//         isLoading: true,
//       },
//       () => {
//         const { currentAPI, currentAPI_Reach } = this.props;
//         postUrl(`${currentAPI}/login`, JSON.stringify(data))
//           .then(res => res.json())
//           .then(({ access_token, reach_access_token, error, user }) => {
//             if (access_token) {
//               login(
//                 {
//                   access_token,
//                   reach_access_token,
//                   username: user.username,
//                 },
//                 currentAPI_Reach
//               );
//               logEvent('login', `login`);
//             } else {
//               this.setState({
//                 error: error,
//                 isLoading: false,
//               });
//             }
//           });
//       }
//     );
//   };

//   render() {
//     const {
//       t,
//       i18n: { language },
//     } = this.props;
//     const { error, isLoading } = this.state;
//     return (
//       <LoginPageView
//         t={t}
//         language={language}
//         onSubmit={this.handleLoginSubmit}
//         isLoading={isLoading}
//         authError={error}
//       />
//     );
//   }
// }

// Login.getInitialProps = async () => ({
//   namespacesRequired: ['common'],
// });

// export default withTranslation('common')(Login);

import { PureComponent } from 'react';
import Router from 'next/router';
import { login } from '../utils/auth';
// @ts-ignore
import { withTranslation } from '../i18n';
import { logEvent, logPageView } from '../utils/analytics';
import { postUrl } from '../utils/api.js';
import { loggedIn } from '../utils/auth.js';
import LoginPageView from '../components/LoginPageView';

type MyProps = {
  t: (arg: string) => string;
  i18n: {
    language: string;
  };
  currentAPI: string;
  currentAPI_Reach: string;
  currentReachURL: string;
};

type MyState = {
  isLoading: boolean;
  error: string;
};

class Login extends PureComponent<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }

  static async getInitialProps() {
    return {
      namespacesRequired: ['common'],
    };
  }

  state = {
    isLoading: false,
    error: '',
  };

  changeState() {
    this.setState({
      error: '',
      isLoading: false,
    });
  }

  componentDidMount() {
    logPageView(window.location.pathname);
    if (loggedIn()) {
      Router.push('/'); // redirect if you're already logged in
    }
  }

  handleLoginSubmit = (data: { username: string; password: string }) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const { currentAPI, currentAPI_Reach } = this.props;
        postUrl(`${currentAPI}/login`, JSON.stringify(data))
          .then(res => res.json())
          .then(({ access_token, reach_access_token, error, user }) => {
            if (access_token) {
              login(
                {
                  access_token,
                  reach_access_token,
                  username: user.username,
                },
                currentAPI_Reach
              );
              logEvent('login', `login`);
            } else {
              this.setState({
                error: error,
                isLoading: false,
              });
            }
          });
      }
    );
  };

  render() {
    const {
      t,
      i18n: { language },
      currentReachURL,
    } = this.props;
    const { error, isLoading } = this.state;
    return (
      <LoginPageView
        t={t}
        language={language}
        onSubmit={this.handleLoginSubmit}
        isLoading={isLoading}
        authError={error}
        currentReachURL={currentReachURL}
        changeState={this.changeState}
      />
    );
  }
}

export default withTranslation('common')(Login);
